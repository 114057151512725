(function($) {
	const $footer = $('.footer');

	if ( $('.single-post').length ) {

		$('.single-post .header').append('<progress value="0" class="progressive-scrollbar" id="progressive-scrollbar"></progress>');

		const getMax = function() {
			let height = $(document).height() - $(window).height();
			height -= $footer.height();
			return height;
		};

		const getValue = function() {
			return $(window).scrollTop();
		};

		if ( 'max' in document.createElement('progress') ) {
			const progressBar = $('progress');
			progressBar.attr({ max: getMax() });

			$(window).on('load', function() {
				progressBar.attr({ value: getValue() });
			});

			$(document).on('scroll', function() {
				progressBar.attr({ value: getValue() });
			});

			$(window).resize(function() {
				progressBar.attr({ max: getMax(), value: getValue() });
			});
		} else {
			let progressBar = $('.progress-bar'),
			max = getMax(),
			value,
			width;

			const getWidth = function() {
				value = getValue();
				width = (value / max) * 100;
				width = width + '%';
				return width;
			};

			const setWidth = function() {
				progressBar.css({ width: getWidth() });
			};

			$(document).on('scroll', setWidth);
			$(window).on('resize', function() {
				max = getMax();
				setWidth();
			});
		}
	}
})(jQuery);
