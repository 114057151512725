(function($) {

	var $window = $(window),
		$document = $(document),
		$body = $('body'),
		$header,
		$alert,
		scrollTop = 0,
		logScrollTop = 0,
		headerHeight = 0,
		adminBarHeight = 0,
		alertHeight = 0,
		$wpadminbar = null,
		alertRemoved = false,
		$scrollSections, 
		$scrollLinks,
		activeSection,
		isScrolling,
		$arrowElem,
		$sidebar,
		sidebarOffset;

	function responding() {
		if ( $header.length ) {
			headerHeight = $header.height();
		}

		if ( $wpadminbar.length ) {
			adminBarHeight = $wpadminbar.height();

			headerHeight = headerHeight + adminBarHeight;
		}

		if ( $alert.length && ! alertRemoved ) {
			alertHeight = $alert.height();
			headerHeight = headerHeight + alertHeight;
		}

		if ( $sidebar.length ) {
			sidebarOffset = $sidebar.offset().top;
		}

		scrolling();
	}

	function resizing() {
		if ( $wpadminbar.length ) {
			adminBarHeight = $wpadminbar.height();

			headerHeight = headerHeight + adminBarHeight;
		}
	}

	function clearScroll() {
		$("html, body").stop();
	}

	function scrollToPos(pos) {
		var extra = 100;
		$("html, body").animate({ scrollTop: pos - extra });
		$(window).one("mousedown DOMMouseScroll mousewheel keyup", clearScroll);
	}

	function scrollToElement(href) {
		var $el = $(href);

		if (!$el.length) {
			$el = $("[name="+href.substring(1)+"]");
		}

		if ($el.length) {
			var pos = $el.offset(),
			offset = 20;

			if (href === "#top") {
				pos.top = 0;
			}

			scrollToPos(pos.top - offset);
		}
	}

	function scrollTo(e) {
		var $target = $(e.currentTarget),
		href = $target.attr("href");

		if (href !== "#") {
			e.stopPropagation();
			e.preventDefault();
			scrollToElement(href);
		} else {
			scrollToPos(0);
		}
	}

	function scrolling() {
			scrollTop =  $window.scrollTop();

			if ( $sidebar.length ) {
				if ( scrollTop > sidebarOffset ) {
					$body.addClass('scrolled-past-sidebar');
				} else {
					$body.removeClass('scrolled-past-sidebar');
				}
			}

			if ( scrollTop > headerHeight ) {
				$body.addClass('scrolling');

				if ( scrollTop >= logScrollTop ) { // scrolling down
					if ( parseInt($header.css('top')) > '-' + headerHeight ) {
						$header.stop().css('top', '-' + headerHeight + 'px' );
						$body.removeClass('scrolling-up').addClass('scrolling-down');
					}

				} else { // scrolling up
					if ( parseInt($header.css('top')) < 0 ) {
						$header.css('top', '-' + headerHeight + 'px' )
						.animate({
							top: adminBarHeight
						}, 200);
						$body.removeClass('scrolling-down').addClass('scrolling-up');
					}
				}

				if ( ! isScrolling ) {
					isScrolling = true;
				}
			} else {
				if ( isScrolling ) {
					$body.removeClass('scrolling').removeClass('scrolling-up').removeClass('scrolling-down');
					resetHeight = 0;
					$header.stop().css('top', 0);
				}

				isScrolling = false;
			}

			logScrollTop = scrollTop;
		}

	function init() {
		$header = $('.header');
		$wpadminbar = $('#wpadminbar');
		$alert = $('.js-alert');

		$('.js-alert-close').on('click', function () {
			$alert.remove();
			alertHeight = 0;
			alertRemoved = true;

			responding();
		});

		$window.on("mqchange.mediaquery", responding)
			 .on("resize", resizing)
			 .on("scroll", scrolling);

		$sidebar = $('.sidebar');

		if ( $sidebar.length ) {
			sidebarOffset = $sidebar.offset().top;
		}

		responding();
	}

	init();// 

})(jQuery);
