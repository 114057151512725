/*------------------------------>
	Site > Modules > Navigation
<------------------------------*/

(function($) {

		var $body = $('body'),
			$mainNavigation,
			$mainNavigationItems,
			$mainNavigationHandle,
			mainNavigationVisible = false,
			$search,
			$searchHandle,
			searchVisible = false;

		// jQuery formatted selector to search for focusable items
		var focusableElementsString = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";

		// store the item that has focus before opening the modal window
		var focusedElementBeforeModal;

		function hoverOn(e) {
			$body.addClass('hover');
			$(this).addClass('hover');
		}

		function hoverOff(e) {
			$body.removeClass('hover');
			$(this).removeClass('hover');
		}

		function focusOn(e) {
			$body.addClass('hover');
			$(this).parent().addClass('focus');
		}

		function focusOff(e) {
			$body.removeClass('hover');
			$(this).parent().removeClass('focus');
		}

		function trapEscapeKey(obj, evt) {

		    // if escape pressed
		    if (evt.which == 27) {

		        // get list of all children elements in given object
		        var o = obj.find('*');

		        // get list of focusable items
		        var cancelElement;
		        cancelElement = o.filter("#cancel");

		        // close the modal window
		        cancelElement.click();
		        evt.preventDefault();
		    }

		}

		function trapTabKey(obj, evt) {

		    // if tab or shift-tab pressed
		    if (evt.which == 9) {

		        // get list of all children elements in given object
		        var o = obj.find('*');

		        // get list of focusable items
		        var focusableItems;
		        focusableItems = o.filter(focusableElementsString).filter(':visible');

		        // get currently focused item
		        var focusedItem;
		        focusedItem = $(':focus');

		        // get the number of focusable items
		        var numberOfFocusableItems;
		        numberOfFocusableItems = focusableItems.length;

		        // get the index of the currently focused item
		        var focusedItemIndex;
		        focusedItemIndex = focusableItems.index(focusedItem);

		        if (evt.shiftKey) {
		            //back tab
		            // if focused on first item and user preses back-tab, go to the last focusable item
		            if (focusedItemIndex == 0) {
		            	focusableItems.get(numberOfFocusableItems - 1).focus();
		            	evt.preventDefault();
		            }

		        } else {
		            //forward tab
		            // if focused on the last item and user preses tab, go to the first focusable item
		            if (focusedItemIndex == numberOfFocusableItems - 1) {
		            	focusableItems.get(0).focus();
		            	evt.preventDefault();
		            }
		        }
		    }

		}

		function setInitialFocusModal(obj) {
		    // get list of all children elements in given object
		    var o = obj.find('*');

		    // set focus to first focusable item
		    var focusableItems;
		    focusableItems = o.filter(focusableElementsString).filter(':visible').first().focus();

		}

		function enterButtonModal() {
		    // BEGIN logic for executing the Enter button action for the modal window
		    alert('form submitted');
		    // END logic for executing the Enter button action for the modal window
		    hideModal();
		}

		function setFocusToFirstItemInModal(obj){
		    // get list of all children elements in given object
		    var o = obj.find('*');

		    // set the focus to the first keyboard focusable item
		    o.filter(focusableElementsString).filter(':visible').first().focus();
		}

		function toggleSearch(e) {
			e.preventDefault();

			if ( searchVisible ) {
				$searchHandle.removeClass('is-active').text('Menu');
				$search.removeClass('is-active').attr('aria-hidden', 'true');
				searchVisible = false;

				$body.removeClass('fs-navigation-lock')
					.off('focusin','#page');

			    $('#page').attr('aria-hidden', 'false');

			    focusedElementBeforeModal.focus();
			} else {

				if ( mainNavigationVisible ) {
					$('.js-navigation-handle').trigger('click');
				}

				$searchHandle.addClass('is-active').text('Close');
				$search.addClass('is-active').attr('aria-hidden', 'false');
				searchVisible = true;

				$body.addClass('fs-navigation-lock')
					.on('focusin','#page',function() {
						$('.search-form .search-field').focus();
			    	});

				$('#page').attr('aria-hidden', 'true');

			    focusedElementBeforeModal = $(':focus');

				setTimeout(function() { 
					$('.search-form .search-field').focus();
				}, 300 );
			}
		}

		function toggleMobileMenu(e) {
			e.preventDefault();

			if ( mainNavigationVisible ) {
				$mainNavigationHandle.removeClass('is-active').text('Menu');
				$mainNavigation.removeClass('is-active').attr('aria-hidden', 'true');
				mainNavigationVisible = false;

				$body.removeClass('fs-navigation-lock')
					.off('focusin','#page');

			    $('#page').attr('aria-hidden', 'false');

			    focusedElementBeforeModal.focus();
			} else {
				if ( searchVisible ) {
					$('.js-search-handle').trigger('click');
				}

				$mainNavigationHandle.addClass('is-active').text('Close');
				$mainNavigation.addClass('is-active').attr('aria-hidden', 'false');
				mainNavigationVisible = true;

				$body.addClass('fs-navigation-lock')
					.on('focusin','#page',function() {
				        setFocusToFirstItemInModal($mainNavigation);
			    	});

				$('#page').attr('aria-hidden', 'true');

			    focusedElementBeforeModal = $(':focus');

			    setFocusToFirstItemInModal($mainNavigation);
			}
		}

		function init() {
			$mainNavigation = $('.js-navigation');

			$mainNavigationItems = $mainNavigation.find('.menu-item')
				.on('mouseover', hoverOn)
				.on('mouseout', hoverOff);

			$mainNavigationItems.find('a')
				.on('focus', focusOn)
				.on('blur', focusOff);

			$mainNavigationHandle = $('.js-navigation-handle')
				.on('click touchstart', toggleMobileMenu);

			$('.js-menu-item-expand').on('click', function(e) {
				var $this = $(this),
					$parent = $this.parent();

				if ( $parent.hasClass('expanded') ) {
					$parent.removeClass('expanded');
				} else {
					$parent.addClass('expanded');
				}
			});


			$search = $('.js-search');
			$searchHandle = $('.js-search-handle')
				.on('click touchstart', toggleSearch);


			$('.js-search-expand').on('focus', function() {
				var $this = $(this);
				
				$this.parents('.js-search-expand-target').addClass('expanded');
			}).blur(function() {
				var $this = $(this);
				if ( ! $this.val().length ) {
					$this.parents('.js-search-expand-target').removeClass('expanded');
				}
			});
		}


		init();
})(jQuery);