(function($) {

	var $footnoteLinks = $('.footnote-link'),
		$footnotesSection = $('.footnotes'),
		$footnotes = $('.footnote');

	if ( $footnotesSection.length ) {
		$footnoteLinks.on('click', function(e) {
			e.preventDefault();

			var $this = $(this),
				$footnote = $($this.attr('href')),
				$elem = $('<div/>');

			if ($this.hasClass('footnote-open')) {
				$('.' + $this.attr('id')).remove();
				$this.removeClass('footnote-open');
			} else {
				$elem.html( '<a href="#" class="footnote-window-close">Close</a><span>' + $footnote.html() + '</span>' );
				$elem.addClass('footnote-window').addClass($this.attr('id'));
				$elem.find('.footnote-return').attr('href', $this.attr('href')).html('[&larrhk;]');
				$this.parent().after( $elem );
				$this.addClass('footnote-open');

				$('.footnote-window-close').on('click', function(e) {
					e.preventDefault();

					$(this).parent().remove();
					$this.removeClass('footnote-open');
				});
			}
		});
	}

})(jQuery);
