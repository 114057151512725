(function($) {

	var $headings = $('.section-header-heading:not([data-toc-ignore])'),
		$menu = $('.js-jump-links');

	$.each($headings, function(key, value) {
		var $this = $(this),
			text = $this.text().replace('#', ''),
			slug = $this.data('heading-id');

		var $elem = $('<a/>');
		$elem.attr('href', '#' + slug);
		$elem.text(text);
		$elem.addClass('jump-link');
		$menu.append( $elem ).parents('.sidebar').addClass('show');
	});

})(jQuery);