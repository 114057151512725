(function($) {

	var role = '',
		$people = $('.js-people-item'),
		$roles = $('.js-people-role'),
		$grid = $('.js-people-grid');

	function filterPeople(index) {
		var $this = $(this),
			roles = $this.data('roles').split(',');

		if ( roles.indexOf( role ) !== -1 ) {
			return $this;
		}
	}

	function selectRole(e) {
		var $this = $(this);

		role = $this.val();

		$roles.removeClass('selected');
		$this.addClass('selected');

		$grid.isotope({ filter: '[data-roles*="' + role + '"]' });
	}

	function init() {
		$roles.on('click', selectRole);
		$grid.isotope({
  			itemSelector: '.js-people-item',
  			layoutMode: 'fitRows'
		});

		$roles.eq(0).trigger('click');
	}

	init();

})(jQuery);
