(function($) {

	var clipboard = new ClipboardJS('.js-clipboard'),
		tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')),
		tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl);
		});

	clipboard.on('success', function (e) {
	    let trigger_button = e.trigger;
	 
	    trigger_button.setAttribute('data-bs-original-title', 'Copied!');
	 
	    let btn_tooltip = bootstrap.Tooltip.getInstance(trigger_button);
	 
	    btn_tooltip.show();
	 
	    trigger_button.setAttribute('data-bs-original-title', 'Copy to clipboard');
	    
	});

})(jQuery);