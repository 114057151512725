(function($) {

	function toggleFilters(e, state) {

		if ( state.minWidth > 740 ) {
			$('#filters.collapse').addClass('show');
			$('.filter-expand').removeClass('collapsed');
		}
	}

	Formstone.Ready(function() {
		$(window).on("mqchange.mediaquery", toggleFilters);

		if (!$.mediaquery("state")) {
			$.mediaquery({
				minWidth     : [ 320, 576, 768, 992, 1200 ],
				maxWidth     : [ 1200, 992, 768, 576, 320 ],
				minHeight    : [ 400, 800 ],
				maxHeight    : [ 800, 400 ]
			});

		} else {
			toggleFilters({}, $.mediaquery("state"));
		}
	});

})(jQuery);